import React, { useState, useEffect } from 'react';
import '../styles/Home.css';
import logoImage from '../assets/WhatsApp_Image_2024-01-01_at_23.05.38-removebg-preview.png';
import { motion } from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useVideo } from '../hooks/useVideo';
import { triggerConverteAiEvent } from '../utils/videoHelpers';
import videoTest from '../assets/videotest.mp4';
import { firestore } from '../firebase-config'; // Ensure this import is correct based on your Firebase setup
import { collection, addDoc } from 'firebase/firestore'; // Import Firestore functions
import UserModal from '../components/userModal';

const phrases = [
  'CONSEGUIR CARTÕES BLACK',
  'VIAJAR TODO ANO DE GRAÇA',
  'TER UMA RENDA EXTRA SEM TRABALHAR'
];

function Home() {
  const [showButton, setShowButton] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isMuted, setIsMuted] = useState(true);
  const [videoEnded, setVideoEnded] = useState(false);
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', phone: '' });
  const [isFormValid, setIsFormValid] = useState(false);
  
  const videoId = '574Bb53d-Dc84-48Fb-B8fe-8542591B26ff(1).mp4';
  const { videoRef, videoURL, isLoading, isPaused } = useVideo(videoId, triggerConverteAiEvent, setVideoEnded, setShowButton);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentPhraseIndex(prevIndex => (prevIndex + 1) % phrases.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
    const isValid = Object.values(formData).every(field => field.trim() !== '') && emailValid;
    setIsFormValid(isValid);
  }, [formData]);

  const toggleMuteOrPause = () => {
    const video = videoRef.current;
    if (video.muted) {
      video.muted = false;
      setIsMuted(false);
    } else {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    }
  };

  const handleButtonClick = (event) => {
    event.stopPropagation();
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    let formattedValue = value;
  
    if (name === 'phone') {
      // Format the phone number to Brazilian format (e.g., (XX) XXXXX-XXXX)
      formattedValue = value
        .replace(/\D/g, '') // Remove all non-digit characters
        .replace(/^(\d{2})(\d)/g, '($1) $2') // Add parentheses around the area code
        .replace(/(\d)(\d{4})$/, '$1-$2'); // Add a hyphen before the last four digits
    }
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(firestore, 'users'), formData); // Save form data to Firestore
      const { name, email, phone } = formData;
      const checkoutUrl = `https://pay.hub.la/xZb5C9qivyu68VZeQQxY?_gl=1*xv5izs*_gcl_au*NDQ0MDA0NzAxLjE3MTM2NTE5NTA&name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&phone=${encodeURIComponent(phone)}`;
      window.open(checkoutUrl, '_blank'); // Open checkout page in a new tab
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div className={isMobile ? "homepage-mobile" : "homepage"}>
      <div className="fixed-header">
        <span>
          <span style={{ fontSize: isMobile ? 12 : 18 }}>ASSISTA O VIDEO ABAIXO E APRENDA COMO: </span><br />
          <motion.b
            style={{ fontSize: isMobile ? 17 : 25 }}
            key={currentPhraseIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <span>{phrases[currentPhraseIndex]}</span>
          </motion.b>
        </span>
      </div>
      {!isLoading && (
        <div className="header">
          <div className="text-container">
            <div className="text-block">
              <h1 style={{ lineHeight: '0.8', textAlign: 'center', display: "flex", flexDirection: 'column' }}>
                <span style={{ fontSize: isMobile ? '18px' : '33px', width: isMobile ? '90%' : '50%', marginLeft: 'auto', marginRight: 'auto', lineHeight: 1, fontWeight: 'bold' }}>O SEU CARTÃO TEVE, RESGATE O DINHEIRO PERDIDO, CONSIGA O SEU BLACK E COLOQUE NO BOLSO 5 MIL REAIS EXTRAS POR MÊS!</span>
                <span style={{ fontSize: isMobile ? '15px' : '28px', marginBottom: 25, marginTop: 25, fontWeight: 'bold' }}>MESMO QUE VOCE TENHA O NOME SUJO!</span>
              </h1>
            </div>
            {!isMobile && <img src={logoImage} alt="Clube Multimilhas Logo" className="logo" />}
          </div>
        </div>
      )}      {isLoading && (
        <div className="loading-container">
          <div className="spinner"></div>
          <p>Carregando...</p>
        </div>
      )}
      <motion.div
        className={`video-container ${videoEnded ? 'video-ended' : ''}`}
        onClick={toggleMuteOrPause}
        initial={{ opacity: 0 }}
        animate={{ opacity: isLoading ? 0 : 1 }}
        transition={{ duration: 1 }}
      >
        {videoURL && (
          <video
            ref={videoRef}
            muted={isMuted}
            autoPlay
            playsInline
            style={{
              display: isLoading ? 'none' : 'block',
              filter: isMuted ? 'brightness(0.5)' : 'none'
            }}
          >
            <source src={videoURL} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        {isMuted && !isLoading && <div className={isMobile ? "unmute-prompt-mobile" : "unmute-prompt"}> {isMobile ? 'TOQUE PARA ATIVAR O SOM' : 'CLIQUE PARA ATIVAR O SOM!'}</div>}
        {isPaused && !isLoading && <div className="play-pause-icon pause-icon">▶</div>}
        <button onClick={handleButtonClick} className={`action-button ${showButton ? 'show' : ''} ${videoEnded ? 'grow' : ''}`}>Quero a mudança já!</button>
        {!isLoading && (
          <>
            <div className="chevron-left">❮❮❮</div>
            <div className="chevron-right">❯❯❯</div>
          </>
        )}
      </motion.div>
      {isMobile && <img src={logoImage} alt="Clube Multimilhas Logo" className="logo-mobile" />}

      <div className="mute-icon" onClick={toggleMuteOrPause}>
        {isMuted ? '🔇' : '🔊'}
      </div>

      <UserModal
        showModal={showModal}
        handleModalClose={handleModalClose}
        handleFormSubmit={handleFormSubmit}
        handleInputChange={handleInputChange}
        formData={formData}
        isFormValid={isFormValid}
      />
    </div>
  );
}

export default Home;
