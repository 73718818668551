import React from 'react';
import { firestore, serverTimestamp } from '../firebase-config';
import { collection, addDoc } from 'firebase/firestore';
import profilePic from '../assets/profilePic.png'; // Add a profile picture

const comments = [
  {
    author: "@joaodasilva",
    timestamp: serverTimestamp(), // Add server timestamp
    text: "Esse método realmente funciona, já estou vendo os resultados!",
    likes: 55,
    profilePic: profilePic
  },
  {
    author: "@mariaoliveira",
    timestamp: serverTimestamp(), // Add server timestamp
    text: "Incrível! Nunca pensei que fosse tão fácil ganhar dinheiro assim.",
    likes: 34,
    profilePic: profilePic
  },
  {
    author: "@carlossouza",
    timestamp: serverTimestamp(), // Add server timestamp
    text: "Vale muito a pena, recomendo a todos que querem melhorar de vida.",
    likes: 76,
    profilePic: profilePic
  },
  {
    author: "@anapaula",
    timestamp: serverTimestamp(), // Add server timestamp
    text: "Estou impressionada com os resultados rápidos que consegui.",
    likes: 49,
    profilePic: profilePic
  },
  {
    author: "@rafaelalmeida",
    timestamp: serverTimestamp(), // Add server timestamp
    text: "Patrick sempre trazendo conteúdos de valor, muito obrigado!",
    likes: 62,
    profilePic: profilePic
  },
  {
    author: "@julianagomes",
    timestamp: serverTimestamp(), // Add server timestamp
    text: "Nunca pensei que fosse tão simples ganhar dinheiro extra!",
    likes: 88,
    profilePic: profilePic
  },
  {
    author: "@felipearaujo",
    timestamp: serverTimestamp(), // Add server timestamp
    text: "Excelente conteúdo, aprendi muito e já estou aplicando!",
    likes: 73,
    profilePic: profilePic
  },
  {
    author: "@marcelocosta",
    timestamp: serverTimestamp(), // Add server timestamp
    text: "Esse método mudou minha vida financeira, recomendo a todos!",
    likes: 91,
    profilePic: profilePic
  },
  {
    author: "@fernandasantos",
    timestamp: serverTimestamp(), // Add server timestamp
    text: "Simplesmente fantástico! Vale cada segundo investido.",
    likes: 67,
    profilePic: profilePic
  },
  {
    author: "@rodrigopereira",
    timestamp: serverTimestamp(), // Add server timestamp
    text: "Conteúdo de alta qualidade, como sempre. Parabéns, Patrick!",
    likes: 59,
    profilePic: profilePic
  },
  {
    author: "@luanamartins",
    timestamp: serverTimestamp(), // Add server timestamp
    text: "Já comecei a ver os resultados, muito feliz com isso!",
    likes: 85,
    profilePic: profilePic
  },
  {
    author: "@gustavohenrique",
    timestamp: serverTimestamp(), // Add server timestamp
    text: "Impressionante como é fácil seguir o método e ter resultados.",
    likes: 72,
    profilePic: profilePic
  },
  {
    author: "@beatrizribeiro",
    timestamp: serverTimestamp(), // Add server timestamp
    text: "Patrick sempre trazendo o melhor conteúdo, agradeço demais!",
    likes: 64,
    profilePic: profilePic
  },
  {
    author: "@ricardosilva",
    timestamp: serverTimestamp(), // Add server timestamp
    text: "Estou ganhando dinheiro de forma consistente, obrigado!",
    likes: 92,
    profilePic: profilePic
  }
];

const HardcodedComments = () => {
  const handleAddComments = async () => {
    const commentsCollection = collection(firestore, 'comments');
    
    for (const comment of comments) {
      try {
        await addDoc(commentsCollection, {
          name: comment.author.substring(1),
          timestamp: serverTimestamp(), // Add server timestamp
          comment: comment.text,
          likes: comment.likes,
          dislikes: 0,
          pictureURL: comment.profilePic,
          hardcoded: true
        });
        console.log(`Added comment from ${comment.author}`);
      } catch (error) {
        console.error('Error adding comment: ', error);
      }
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <button onClick={handleAddComments} style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>
        Add Hardcoded Comments to Database
      </button>
    </div>
  );
};

export default HardcodedComments;
