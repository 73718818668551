import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import '../styles/Survey.css';

function Survey() {
  const [step, setStep] = useState(1);
  const [responses, setResponses] = useState({});
  const navigate = useNavigate();

  const options = [
    ['Step 1 - Option 1', 'Step 1 - Option 2', 'Step 1 - Option 3', 'Step 1 - Option 4', 'Step 1 - Option 5'],
    ['Step 2 - Option 1', 'Step 2 - Option 2', 'Step 2 - Option 3', 'Step 2 - Option 4', 'Step 2 - Option 5'],
    ['Step 3 - Option 1', 'Step 3 - Option 2', 'Step 3 - Option 3', 'Step 3 - Option 4', 'Step 3 - Option 5'],
    ['Step 4 - Option 1', 'Step 4 - Option 2', 'Step 4 - Option 3', 'Step 4 - Option 4', 'Step 4 - Option 5'],
    ['Step 5 - Option 1', 'Step 5 - Option 2', 'Step 5 - Option 3', 'Step 5 - Option 4', 'Step 5 - Option 5'],
  ];

  const handleOptionClick = (option) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [step]: option,
    }));

    if (step < 5) {
      setStep(step + 1);
    } else {
      navigate('/home'); // Redirect to the Home page after the survey
    }
  };

  const handleBackClick = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const renderOptions = () => {
    return options[step - 1].map((option) => (
      <motion.button
        key={option}
        onClick={() => handleOptionClick(option)}
        className={`btn btn-outline-light m-2 ${responses[step] === option ? 'selected' : ''}`}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        {option}
      </motion.button>
    ));
  };

  return (
    <div className="survey-container text-center text-white">
      <AnimatePresence mode="wait">
        {step === 1 && (
          <motion.p 
            className="lead"
            key="intro"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            Answer these questions below so we can provide you with the perfect learning experience.
            Your responses will help us tailor the content to best suit your needs and goals.
          </motion.p>
        )}
        <motion.h2 
          key={step}
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 10 }}
        >
          Step {step}
        </motion.h2>
        <motion.div 
          className="options-container"
          key={`options-${step}`}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
        >
          {renderOptions()}
        </motion.div>
      </AnimatePresence>
      <motion.button
        className="btn btn-outline-light mt-4 back-button"
        onClick={handleBackClick}
        initial={{ opacity: step > 1 ? 1 : 0, pointerEvents: step > 1 ? 'auto' : 'none' }}
        animate={{ opacity: step > 1 ? 1 : 0, pointerEvents: step > 1 ? 'auto' : 'none' }}
        exit={{opacity:0}}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </motion.button>
    </div>
  );
}

export default Survey;
