import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './views/Home';
import Live from './views/Live';
import Survey from './views/Survey';
import CommentPage from './views/CommentPage'; // Import the new CommentPage component
import HardcodedComments from './components/HardcodedComments'; // Import the HardcodedComments component
import BurnerPage from './components/BurnerPage'; // Import the HardcodedComments component


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Survey />} />
        <Route path="/home" element={<Home />} />
        <Route path="/live" element={<Live />} />
        <Route path="/comentario" element={<CommentPage />} /> {/* Add the new route */}
        <Route path="/add-comments" element={<HardcodedComments />} /> {/* Add this route */}
{/*         <Route path="/burner" element={<BurnerPage />} /> 
 */}      </Routes>
    </Router>
  );
}

export default App;
