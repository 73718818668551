import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const UserModal = ({ showModal, handleModalClose, handleFormSubmit, handleInputChange, formData, isFormValid }) => {
  return (
    <Modal show={showModal} onHide={handleModalClose} centered className="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title>Complete a sua Compra</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formPhone">
            <Form.Label>Telefone</Form.Label>
            <Form.Control
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3" disabled={!isFormValid}>
            Comprar Agora
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UserModal;
