import React, { useState } from 'react';
import { storage, firestore, ref, getDownloadURL } from '../firebase-config';
import { collection, addDoc, serverTimestamp, query, where, getDocs, deleteDoc, orderBy } from 'firebase/firestore'; // Import additional Firestore functions
import { uploadBytesResumable } from 'firebase/storage';
import '../styles/CommentForm.css';
import YouTubeLogo from '../components/YouTubeLogo';
import SearchIcon from '../components/SearchIcon';
import EllipsisIcon from '../components/EllipsisIcon';
import emptyPic from '../assets/blank-profile-picture-973460_640.webp';

function CommentForm() {
  const [name, setName] = useState('');
  const [text, setText] = useState(''); // Change comment to text
  const [picture, setPicture] = useState(null);
  const [pictureName, setPictureName] = useState(''); // State for picture name
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    let pictureURL = emptyPic; // Default to emptyPic

    if (picture) {
      const storageRef = ref(storage, `profile_pics/${picture.name}`);
      const uploadTask = uploadBytesResumable(storageRef, picture);

      pictureURL = await new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {},
          (error) => reject(error),
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(resolve);
          }
        );
      });
    }

    try {
      await addDoc(collection(firestore, 'comments'), {
        name,
        text, // Use text field to match hardcoded comments
        pictureURL,
        likes: 0, // Initialize likes to 0
        dislikes: 0, // Initialize dislikes to 0
        hardcoded: false, // Indicate that this is not a hardcoded comment
        timestamp: serverTimestamp() // Add server timestamp
      });

      // Query for a hardcoded comment
      const hardcodedQuery = query(collection(firestore, 'comments'), where('hardcoded', '==', true), orderBy('timestamp'));
      const hardcodedSnapshot = await getDocs(hardcodedQuery);

      if (!hardcodedSnapshot.empty) {
        // Get the first hardcoded comment
        const hardcodedDoc = hardcodedSnapshot.docs[0];
        // Delete the hardcoded comment
        await deleteDoc(hardcodedDoc.ref);
      }

      setName('');
      setText(''); // Clear text field
      setPicture(null);
      setPictureName(''); // Clear picture name
      setIsSubmitted(true); // Set submission status to true
    } catch (error) {
      console.error('Erro ao enviar comentário: ', error);
      alert('Erro ao enviar comentário');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setPicture(e.target.files[0]);
      setPictureName(e.target.files[0].name); // Update picture name
    } else {
      setPicture(null);
      setPictureName(''); // Clear picture name if no file is selected
    }
  };

  return (
    <div className="comment-page">
      <div className="live-header-form">
        <YouTubeLogo />
        <div className="header-icons">
          <SearchIcon />
          <EllipsisIcon />
        </div>
      </div>
      <div className="comment-form-container">
        {isSubmitted ? ( // Conditional rendering based on submission status
          <p className="success-text">Comentário enviado com sucesso!</p>
        ) : (
          <form onSubmit={handleSubmit} className="comment-form">
            <h2>Deixe seu comentário</h2>
            <div className="form-group">
              <label htmlFor="name">Usuário do Instagram:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="text">Comentário:</label>
              <textarea
                id="text"
                value={text} // Change comment to text
                onChange={(e) => setText(e.target.value)} // Change comment to text
                required
              ></textarea>
            </div>
            <div className="form-group">
              <label htmlFor="picture">Uma foto sua:</label>
              <input
                type="file"
                id="picture"
                accept="image/*"
                onChange={handleFileChange}
              />
              <label htmlFor="picture" className="custom-file-upload">
                Escolher arquivo
              </label>
              {pictureName && <span className="picture-name">{pictureName}</span>} {/* Display picture name */}
            </div>
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Enviando...' : 'Enviar'}
            </button>
          </form>
        )}
      </div>
      <p className='bonus-text'>{isSubmitted ? 'Agora basta nos avisar que o seu comentário foi enviado, e o bônus é todo seu!' : 'O seu bônus está a um passo de você!'}</p>
    </div>
  );
}

export default CommentForm;
