// src/views/CommentPage.js

import React from 'react';
import CommentForm from '../components/CommentForm';

function CommentPage() {
  return (
    <div>
      <CommentForm />
    </div>
  );
}

export default CommentPage;
