import { useEffect, useRef, useState } from 'react';
import { storage, ref, getDownloadURL } from '../firebase-config';

export const useVideo = (videoId, triggerConverteAiEvent, setVideoEnded, setShowButton) => {
  const videoRef = useRef(null);
  const [videoURL, setVideoURL] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [videoReachedTargetTime, setVideoReachedTargetTime] = useState(false);

  useEffect(() => {
    const fetchVideoURL = async () => {
      const videoStorageRef = ref(storage, videoId);
      try {
        const url = await getDownloadURL(videoStorageRef);
        console.log('Video URL:', url);
        setVideoURL(url);
      } catch (error) {
        console.error("Failed to fetch video URL: ", error);
      }
    };

    fetchVideoURL();
  }, [videoId]);

  useEffect(() => {
    if (videoRef.current) {
      const video = videoRef.current;

      const attemptPlay = async () => {
        try {
          await video.play();
          console.log('Video is playing');
          triggerConverteAiEvent('play');
        } catch (error) {
          console.error("Failed to play video: ", error);
        }
      };

      video.addEventListener('canplay', () => {
        console.log('Video can play');
        setIsLoading(false);
        attemptPlay();
      });

      video.addEventListener('timeupdate', () => {
        if (video.currentTime >= 238 && !videoReachedTargetTime) { // Check for 3:58 (238 seconds)
          triggerConverteAiEvent('Sales pitch reached');
          setVideoReachedTargetTime(true);
          setShowButton(true); // Set showButton to true at 3:58
        }
      });

      video.addEventListener('ended', () => {
        setIsPaused(true);
        setVideoEnded(true);
        triggerConverteAiEvent('ended');
      });

      video.addEventListener('pause', () => {
        setIsPaused(true);
        triggerConverteAiEvent('pause');
      });

      video.addEventListener('play', () => {
        setIsPaused(false);
        triggerConverteAiEvent('play');
      });

      return () => {
        video.removeEventListener('timeupdate', () => {});
        video.removeEventListener('ended', () => {});
        video.removeEventListener('pause', () => {});
        video.removeEventListener('play', () => {});
        video.removeEventListener('canplay', attemptPlay);
      };
    }
  }, [videoURL, videoReachedTargetTime, triggerConverteAiEvent, setVideoEnded, setShowButton]);

  return { videoRef, videoURL, isLoading, isPaused };
};
