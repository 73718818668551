import React, { useState, useEffect } from 'react';
import '../styles/Live.css';
import YouTubeLogo from '../components/YouTubeLogo';
import profilePic from '../assets/profilePic.png';
import { motion } from 'framer-motion';
import ThumbsUpIcon from '../components/ThumbsUpIcon';
import ThumbsDownIcon from '../components/ThumbsDownIcon';
import ShareIcon from '../components/ShareIcon';
import SaveIcon from '../components/SaveIcon';
import SearchIcon from '../components/SearchIcon';
import EllipsisIcon from '../components/EllipsisIcon';
import CommentsList from '../components/CommentsList';
import { useVideo } from '../hooks/useVideo';
import { triggerConverteAiEvent } from '../utils/videoHelpers';
import videoTest from '../assets/videotest.mp4';
import UserModalLive from '../components/userModalLive';
import { collection, addDoc } from 'firebase/firestore'; // Import Firestore functions
import { firestore } from '../firebase-config'; // Ensure this import is correct based on your Firebase setup

const phrases = [
  'CONSEGUIR CARTÕES BLACK',
  'VIAJAR TODO ANO DE GRAÇA',
  'TER UMA RENDA EXTRA SEM TRABALHAR'
];

function Live() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [watchersCount, setWatchersCount] = useState(789);
  const [isMuted, setIsMuted] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const videoId = '574Bb53d-Dc84-48Fb-B8fe-8542591B26ff(1).mp4';
  const { videoRef, videoURL, isLoading, isPaused } = useVideo(videoId, triggerConverteAiEvent, setVideoEnded, setShowButton);
  const [formData, setFormData] = useState({ name: '', email: '', phone: '' });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
    const isValid = Object.values(formData).every(field => field.trim() !== '') && emailValid;
    setIsFormValid(isValid);
  }, [formData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentPhraseIndex(prevIndex => (prevIndex + 1) % phrases.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const randomIncreaseDecrease = () => {
      const intervalTime = Math.floor(Math.random() * 5000) + 10000; // Interval between 10 and 15 seconds
  
      setWatchersCount(prevCount => {
        const increase = Math.floor(Math.random() * 51) + 50; // Increase between 50 and 100
        const decrease = Math.floor(Math.random() * 51) + 50; // Decrease between 50 and 100

        if (prevCount < 1500) {
          // Increase more than decrease if below 1500
          if (Math.random() < 0.7) { // 70% probability to increase
            return prevCount + increase;
          } else {
            return Math.max(prevCount - decrease, 0);
          }
        } else {
          // Decrease more than increase if above 1500
          if (Math.random() < 0.7) { // 70% probability to decrease
            return Math.max(prevCount - decrease, 0);
          } else {
            return prevCount + increase;
          }
        }
      });
  
      setTimeout(randomIncreaseDecrease, intervalTime);
    };
  
    randomIncreaseDecrease(); // Start the first call
  
    return () => clearTimeout(randomIncreaseDecrease);
  }, []);

  const toggleMuteOrPause = () => {
    const video = videoRef.current;
    if (video.muted) {
      video.muted = false;
      setIsMuted(false);
    } else {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(firestore, 'users'), formData); // Save form data to Firestore
      const { name, email, phone } = formData;
      const checkoutUrl = `https://pay.hub.la/xZb5C9qivyu68VZeQQxY?_gl=1*xv5izs*_gcl_au*NDQ0MDA0NzAxLjE3MTM2NTE5NTA&name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&phone=${encodeURIComponent(phone)}`;
      window.open(checkoutUrl, '_blank'); // Open checkout page in a new tab
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    let formattedValue = value;
  
    if (name === 'phone') {
      // Format the phone number to Brazilian format (e.g., (XX) XXXXX-XXXX)
      formattedValue = value
        .replace(/\D/g, '') // Remove all non-digit characters
        .replace(/^(\d{2})(\d)/g, '($1) $2') // Add parentheses around the area code
        .replace(/(\d)(\d{4})$/, '$1-$2'); // Add a hyphen before the last four digits
    }
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };

  const handleButtonClick = (event) => {
    event.stopPropagation();
    setShowModal(true);
  };

  return (
    <div className="live-page">
      <div className="live-header">
        <YouTubeLogo />
        <div className="header-icons">
          <SearchIcon />
          <EllipsisIcon />
        </div>
      </div>
      <div className={`video-container-live`} onClick={toggleMuteOrPause}>
        {isLoading && <div className="spinner"></div>}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isLoading ? 0 : 1 }}
          transition={{ duration: 1 }}
          style={{alignItems:'center', display:'flex', flexDirection:'column'}}
        >
          {videoURL && (
            <video
              ref={videoRef}
              muted={isMuted}
              autoPlay
              playsInline
              style={{
                filter: isMuted ? 'brightness(0.5)' : 'none',
                borderStyle: 'none',
                padding: 0,
                borderRadius: 10,
                marginBottom: 20,
                marginTop: 20   
              }}
            >
              <source src={videoURL} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          {isMuted && !isLoading && <div className={isMobile ? "unmute-prompt-mobile" : "unmute-prompt"}>{isMobile ? 'TOQUE PARA ATIVAR O SOM' : 'CLIQUE PARA ATIVAR O SOM!'}</div>}
          {isPaused && !isLoading && <div className="play-pause-icon pause-icon">▶</div>}
          {showButton && (
            <button onClick={handleButtonClick} className={`action-button-live ${showButton ? 'show' : ''}`}>Quero a mudança já!</button>
          )}
        </motion.div>
      </div>
      <div className="video-info">
        <h1 className="video-title">"Multiplique todo o seu Dinheiro em 10 VEZES de forma Garantida!" | Patrick Froio - LIVE EXCLUSIVA #01</h1>
        <span className="viewers-count">
          <div className="pulsing-dot"></div>{watchersCount} assistindo agora
        </span>
        <div className="video-interaction">
          <div className="profile-info">
            <img src={profilePic} alt="Profile Pic" className="profile-pic" />
            <a href="https://www.instagram.com/patrick_froio" target="_blank" rel="noopener noreferrer" className="uploader-name" style={{ textDecoration: 'none', color: 'inherit' }}>Patrick Froio</a>
            <span className="subscribers-count">198 mil</span>
          </div>
          <a href="https://www.instagram.com/patrick_froio" target="_blank" rel="noopener noreferrer" className="subscribe-button" style={{ textDecoration: 'none' }}>Inscrever-se</a>
        </div>
        <div className="interaction-buttons">
          <div className="interaction-group">
            <ThumbsUpIcon />
            <span className="text-sm" style={{borderStyle:'solid', borderWidth:0, borderRightWidth:0.5, paddingRight:10}}>1.2 mil</span>
            <ThumbsDownIcon />
          </div>
          <div className="interaction-group">
            <ShareIcon />
            <span className="text-sm">Compartilhar</span>
          </div>
          <div className="interaction-group">
            <SaveIcon />
            <span className="text-sm">Salvar</span>
          </div>
        </div>
      </div>
      <CommentsList />
      <UserModalLive
        showModal={showModal}
        handleModalClose={handleModalClose}
        handleFormSubmit={handleFormSubmit}
        handleInputChange={handleInputChange}
        formData={formData}
        isFormValid={isFormValid}
      />

    </div>
  );
}

export default Live;
