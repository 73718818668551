import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getFirestore, serverTimestamp } from "firebase/firestore"; // Add serverTimestamp

const firebaseConfig = {
    apiKey: "AIzaSyA4Tp6p2rveOlGIzy5o6Jiy3TgosiQceFQ",
    authDomain: "clubemultimilhas.firebaseapp.com",
    projectId: "clubemultimilhas",
    storageBucket: "clubemultimilhas.appspot.com",
    messagingSenderId: "883077534876",
    appId: "1:883077534876:web:a0107e80fcb4be98028959",
    measurementId: "G-3LYCLJS537"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const firestore = getFirestore(app);

export { storage, ref, getDownloadURL, firestore, serverTimestamp }; // Export serverTimestamp
