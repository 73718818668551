import React, { useEffect, useState } from 'react';
import { firestore } from '../firebase-config';
import { collection, getDocs, query, orderBy, doc, updateDoc } from 'firebase/firestore';
import profilePic from '../assets/profilePic.png'; // Add a profile picture
import '../styles/Live.css';
import CommentOptionsIcon from './CommentOptionsIcon';
import { CommentLikeIcon, CommentDislikeIcon, CommentReplyIcon } from './CommentIcons';

const CommentsList = () => {
  const [comments, setComments] = useState([]);
  const [fetchedComments, setFetchedComments] = useState([]);
  const [userInteractions, setUserInteractions] = useState({}); // Track user interactions
  const [visibleComments, setVisibleComments] = useState(12); // Initial number of visible comments

  useEffect(() => {
    const fetchComments = async () => {
      const q = query(collection(firestore, 'comments'), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const commentsData = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        commentsData.push({
          ...data,
          timestamp: data.timestamp.seconds * 1000, // Store timestamp in milliseconds
          id: doc.id
        });
      });
      setFetchedComments(commentsData);
    };

    fetchComments();
  }, []);

  const getTimeAgo = (timestamp) => {
    let date;

    if (typeof timestamp === 'string') {
      date = new Date(timestamp);
    } else if (typeof timestamp === 'number') {
      date = new Date(timestamp);
    } else {
      return ''; // Return empty string if timestamp format is unrecognized
    }

    const now = Date.now();
    const secondsAgo = Math.floor((now - date.getTime()) / 1000);

    if (secondsAgo < 60) return `há ${secondsAgo} segundos`;
    const minutesAgo = Math.floor(secondsAgo / 60);
    if (minutesAgo < 60) return `há ${minutesAgo} minutos`;
    const hoursAgo = Math.floor(minutesAgo / 60);
    if (hoursAgo < 24) return `há ${hoursAgo} horas`;
    const daysAgo = Math.floor(hoursAgo / 24);
    return `há ${daysAgo} dias`;
  };

  const handleLikeDislike = async (commentId, isLike) => {
    const commentRef = doc(firestore, 'comments', commentId);
    const comment = fetchedComments.find((c) => c.id === commentId);

    if (comment) {
      const userHasLiked = userInteractions[commentId]?.liked;
      const userHasDisliked = userInteractions[commentId]?.disliked;

      let updatedComment = { ...comment };

      if (isLike) {
        if (userHasLiked) {
          updatedComment.likes -= 1;
          setUserInteractions((prev) => ({
            ...prev,
            [commentId]: { liked: false, disliked: false }
          }));
        } else {
          updatedComment.likes += 1;
          if (userHasDisliked) {
            updatedComment.dislikes -= 1;
          }
          setUserInteractions((prev) => ({
            ...prev,
            [commentId]: { liked: true, disliked: false }
          }));
        }
      } else {
        if (userHasDisliked) {
          updatedComment.dislikes -= 1;
          setUserInteractions((prev) => ({
            ...prev,
            [commentId]: { liked: false, disliked: false }
          }));
        } else {
          updatedComment.dislikes += 1;
          if (userHasLiked) {
            updatedComment.likes -= 1;
          }
          setUserInteractions((prev) => ({
            ...prev,
            [commentId]: { liked: false, disliked: true }
          }));
        }
      }

      await updateDoc(commentRef, {
        likes: updatedComment.likes,
        dislikes: updatedComment.dislikes
      });

      setFetchedComments((prevComments) =>
        prevComments.map((c) =>
          c.id === commentId ? updatedComment : c
        )
      );
    }
  };

  const loadMoreComments = () => {
    setVisibleComments((prevCount) => prevCount + 12);
  };

  return (
    <div className="comments-section">
      <h2>Comentários</h2>
      <ul>
        {fetchedComments.slice(0, visibleComments).map((comment, index) => (
          <li key={index} className="comment">
            <div className="comment-content">
              <div className="comment-profile">
                <img src={comment.pictureURL || profilePic} alt="Profile Pic" className="comment-profile-pic" />
                <div className="comment-info">
                  <span className="comment-author">
                    {comment.hardcoded ? (
                      <span>@{comment.name}</span>
                    ) : (
                      <a
                        href={`https://www.instagram.com/${comment.name}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="comment-name-link"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        @{comment.name}
                      </a>
                    )}
                    <span className="comment-time">{getTimeAgo(comment.timestamp)}</span>
                  </span>
                  <span className="comment-text">{comment.text || comment.comment}</span>
                  <div className="comment-interactions">
                    <CommentLikeIcon onClick={() => handleLikeDislike(comment.id, true)} />
                    <span className="text-xs text-[#aaaaaa]">{comment.likes}</span>
                    <CommentDislikeIcon onClick={() => handleLikeDislike(comment.id, false)} />
                    <CommentReplyIcon />
                  </div>
                </div>
              </div>
              <div className="comment-options">
                <CommentOptionsIcon />
              </div>
            </div>
          </li>
        ))}
      </ul>
      {visibleComments < fetchedComments.length && (
        <button onClick={loadMoreComments} className="load-more-button">Carregar mais</button>
      )}
    </div>
  );
};

export default CommentsList;
